import { Menu } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import { get } from "../storage";

//const { SubMenu } = Menu;

class MenuBar extends React.Component {
  state = {
    current: window.location.pathname,
    ClientTitle: "",
    loaded: false,
  };

  handleClick = e => {
    console.log("click ", this.props);
    this.setState({ current: e.key }, () => {
      this.props.history.push(e.key);
    });
  };

  componentDidMount = async () => {
    const ClientTitle = await get("ClientTitle");
    this.setState({ ClientTitle, loaded: true });
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const { current } = this.state;
    return (
      <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal" style={{ marginBottom: "50px" }}>

        <Menu.Item key="/client">
          {this.state.ClientTitle}
        </Menu.Item>
        <Menu.Item key="/licenses">
          Лицензии
        </Menu.Item>
      </Menu>
    );
  }
}

const MenuBarWithRouter = withRouter(MenuBar);

export { MenuBarWithRouter as MenuBar };
