import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MenuBar } from "../components/MenuBar";
import { get } from "../storage";
import { Devices } from "./Devices";
import { Licenses } from "./Licenses";
import { Login } from "./Login";


function PrivateRoute({ component: Component, user, ...rest }) {
  console.log("PrivateRoute", user);
  return (
    <Route
      {...rest}
      render={(props) => user?.SLRid && user?.ClientRid
        ? <><MenuBar /><Component {...props} /></>
        : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />}
    />
  );
}

export class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    console.log("componentDidMount");
    const SLRid = await get("SLRid");
    const ClientRid = await get("ClientRid");

    if (SLRid && ClientRid) {
      this.setState({
        user: { SLRid, ClientRid },
        loaded: true,
      });
    } else {
      this.setState({ loaded: true });
    }
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} user={this.state.user} />
          <PrivateRoute exact path="/client" component={Devices} user={this.state.user} />
          <PrivateRoute exact path="/licenses" component={Licenses} user={this.state.user} />
          <Redirect path="/" to="/client" />
        </Switch>
      </BrowserRouter>
    );
  }
}