import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { HomeScreen } from "./screens/HomeScreen";


ReactDOM.render(
  <HomeScreen />,
  document.getElementById("root"),
);
