import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table, Tag } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { v4 as uuid } from "uuid";
import { api } from "../api";
import { EditableCell, EditableRow } from "../components/EditableTable";
import { get } from "../storage";

export class Devices extends React.Component {
  state = {
    loaded: false,
    clientDevice: [],
    feature: [],
    changed: false,
    searchText: "",
    searchedColumn: "",
    validation: [],
  };

  save = async (deviceRid = null) => {
    // runMobile_ClientDeviceFeatureSave
    console.log("SAVE", this.state.clientDeviceFeature);

    let devices = this.state.clientDeviceFeature;

    if (deviceRid) {
      devices = devices.filter((entry) => entry.deviceRid !== deviceRid);
    }

    console.log("___________________", devices)

    const response = await api.call("runMobile_ClientDeviceFeatureSave", {
      SLRid: await get("SLRid"),
      clientDeviceFeature: devices.map(entry => {
        return {
          ...entry,
          _rowstate: entry._rowstate || "s"
        }
      }),
      clientDevice: this.state.clientDevice.map(entry => ({...entry, _rowstate: "s"})),
    });

    if (response?.validateResults?.length === 0) {
      this.setState({ editRow: null });
      this.getList();
    } else {
      this.setState({ validation: response.validateResults });
    }
  };

  getList = async () => {
    const response = await api.call("runMobile_ClientDeviceFeatureList", {
      SLRid: await get("SLRid"),
    });

    this.setState({
      clientDevice: response.clientDevice,
      feature: response.feature,
      clientDeviceFeature: response.clientDeviceFeature,
      clientFeature: response.clientFeature,
      loaded: true,
      validation: response.validateResults || [],
    });
  };

  componentDidMount() {
    this.getList();
  }

  isRowEpandable = (row) => {
    return true;
  };

  onChangeCheckbox = (deviceRid, featureNumRid, featureRid) => {
    console.log("onChangeCheckbox", deviceRid, featureNumRid, featureRid);

    // ищем девайс и номер фичи
    let clientDeviceFeature = [...this.state.clientDeviceFeature];
    clientDeviceFeature = clientDeviceFeature.filter((entry) => {
      return !(
        entry.clientDeviceRid === deviceRid &&
        entry.featureRid === featureNumRid
      );
    });

      clientDeviceFeature.push({
        rid: uuid().toUpperCase(),
        featureRid: featureNumRid,
        clientDeviceRid: deviceRid,
        clientFeatureRid: featureRid,
        _rowstate: (featureRid ? "s" : "d"),
      });

    this.setState({ clientDeviceFeature, changed: true }, () => {
      //this.save();

      this.setState({ editRow: deviceRid });
    });
  };

  stripDate = (strDate) => {
    if (strDate) {
      return strDate.substring(0, 10);
    } else {
      return "∞";
    }
  };

  getFeaturesByRid = (featureNumRid, deviceRid) => {
    // если текущая лицензия уже существует в списке юзаных, то не провермяем на к-во лицензий;
    const isFeatureUsed = this.state.clientDeviceFeature.filter((entry) => {
      return (
        entry.clientDeviceRid === deviceRid &&
        entry.featureRid === featureNumRid
      );
    }).length;

    return this.state.clientFeature.filter((entry) => {
      if (!isFeatureUsed) {
        return (
          entry.featureRid === featureNumRid && entry.licQty > entry.usedLicQty
        );
      } else {
        return entry.featureRid === featureNumRid;
      }
    });
  };

  renderCell = (entry, record) => {
    //console.log("RECORD", record);
    const value = this.getChecked(record.rid, entry.rid);
    return (
      <Select
        className={value ? "custom-green-bg" : ""}
        style={{ width: "100%" }}
        value={value}
        onChange={(value) => {
          this.onChangeCheckbox(record.rid, entry.rid, value);
        }}
      >
        <Select.Option value={null}>✕</Select.Option>
        {this.getFeaturesByRid(entry.rid, record.rid).map((option) => {
          return (
            <Select.Option key={option.rid} value={option.rid}>
              {this.stripDate(option.dBegin)}
              {">"}
              {this.stripDate(option.dEnd)}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  getColumnSearchProps = (dataIndex, isDate = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? (
        (isDate ? this.stripDate(record[dataIndex]) : record[dataIndex])
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      ) : (
        <>&bbsp;</>
      ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={
            isDate ? this.stripDate(text) : text ? text.toString() : <>&bbsp;</>
          }
        />
      ) : isDate ? (
        this.stripDate(text)
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getChecked = (deviceRid, featureNumRid) => {
    // смотрим есть ли такая запись в state.clientDeviceFeature? находим - true; иначе false;
    let checked = null;

    this.state.clientDeviceFeature.forEach((element) => {
      if (
        element.clientDeviceRid === deviceRid &&
        element.featureRid === featureNumRid
      ) {
        checked = element.clientFeatureRid;
      }
    });

    return checked;
  };

  getSortingDate = (entry, record) => {
    const value = this.getChecked(record, entry.rid);
    if (!value) {
      return new Date(0);
    }
    console.log("RECORD", value);
    const selectedFeature = this.state.clientFeature.filter((entry) => {
      return entry.rid === value;
    });
    if (!selectedFeature.length) {
      return new Date(0);
    }
    console.log("RECORD 1", selectedFeature);

    if (selectedFeature[0].dBegin) {
      return new Date(selectedFeature[0].dBegin);
    } else {
      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 999);

      return oneYearFromNow;
    }
  };

  handleSave = (row) => {
    const newData = [...this.state.clientDevice];
    const index = newData.findIndex((item) => row.rid === item.rid);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState(
      {
        clientDevice: newData,
      },
      () => {
        console.log("INDEX", row);
        this.setState({ editRow: row.rid });
        //this.save();
      }
    );
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const components = {
      body: {
        cell: EditableCell,
        row: EditableRow,
      },
    };

    const columns = [
      {
        title: "Наименование устройства",
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => {
          return ("" + a.title).localeCompare(b.title);
        },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("title"),
      },
      {
        title: "deviceId",
        dataIndex: "deviceId",
        key: "deviceId",
        sorter: (a, b) => {
          return ("" + a.deviceId).localeCompare(b.deviceId);
        },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("deviceId"),
      },
      {
        title: "Активно",
        dataIndex: "dTDisabled",
        key: "dTDisabled",
      },
      {
        title: "Дата регистрации",
        dataIndex: "dTRegister",
        key: "dTRegister",
        //new Date(b.date) - new Date(a.date)
        sorter: (a, b) => {
          return new Date(b.dTRegister) - new Date(a.dTRegister);
        },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("dTRegister", true),
      },
      {
        title: "Примечание",
        dataIndex: "SiteNote",
        key: "SiteNote",
        editable: true,
        render: (text) => (text ? text : <>&nbsp;</>),
        ...this.getColumnSearchProps("SiteNote"),
      },
      ...this.state.feature.map((entry) => {
        return {
          title: entry.title,
          key: "feature" + entry.rid,
          width: "200px",
          sorter: (a, b) => {
            return (
              this.getSortingDate(entry, a.rid) -
              this.getSortingDate(entry, b.rid)
            );
          },
          sortDirections: ["descend", "ascend"],
          render: (text, record) => {
            return this.renderCell(entry, record);
          },
        };
      }),
      {
        title: "",
        dataIndex: "",
        key: "actions",
        render: (row) => {
          console.log("RENDER", row, this.state.editRow);
          return (
            <Button
              type="primary"
              disabled={this.state.editRow !== row.rid}
              onClick={() => {
                this.save(row.rid);
              }}
            >
              Сохранить
            </Button>
          );
        },
      },
    ].map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          customtype: col.customtype,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        {this.state?.validation?.length ? (
          <div style={{ padding: 20 }}>
            {this.state.validation.map((entry, index) => {
              return (
                <Tag key={index} color="error">
                  {entry.text}
                </Tag>
              );
            })}
          </div>
        ) : null}
        <Table
          rowClassName={() => "editable-row"}
          bordered
          components={components}
          size="small"
          pagination={false}
          rowKey="rid"
          dataSource={this.state.clientDevice}
          columns={columns}
        />
      </>
    );
  }
}
