import { Table } from "antd";
import React from "react";
import { api } from "../api";
import { get } from "../storage";

export class Licenses extends React.Component {
  state = {
    loaded: false,

  }
  componentDidMount() {
    this.getList();
  }

  stripDate = (strDate) => {
    if (strDate) {
      return strDate.substring(0, 10);
    } else {
      return "∞";
    }
  }

  getList = async () => {
    const response = await api.call("runMobile_ClientDeviceFeatureList", {
      SLRid: await get("SLRid"),
    });

    this.setState({
      clientDevice: response.clientDevice,
      feature: response.feature,
      clientDeviceFeature: response.clientDeviceFeature,
      clientFeature: response.clientFeature,
      loaded: true,
    });
  }


  render() {
    if (!this.state.loaded) {
      return null;
    }

    const columns = [
      {
        title: "Функция",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Дата начала",
        dataIndex: "dBegin",
        key: "dBegin",
        render: (text) => this.stripDate(text),
      },
      {
        title: "Дата окончания",
        dataIndex: "dEnd",
        key: "dEnd",
        render: (text) => this.stripDate(text),
      },
      {
        title: "Лицензий",
        key: "lic",
        render: (text, record) => { return record.usedLicQty + "/" + record.licQty; },
      },
    ];

    return <>
      <Table
        size="small"
        pagination={false}
        rowKey="rid"
        dataSource={this.state.clientFeature}
        columns={columns}
      />
    </>;
  }
}